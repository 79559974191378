// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';
export const WEB_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
export const ENABLE_REDUX_LOGGER = false;

export const DEFAULT_USERNAME = '';
export const DEFAULT_PASSWORD = '';

export default {};
