import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_TASKS = '@products/get-tasks';
export const REQUEST_TASKS = '@products/request-tasks';

export function getTasks(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.query != null) {
      params.query = filter.query;
    }
  }

  const request = axios.get(`${API_BASE_URL}/task`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_TASKS });

    request.then((response) => dispatch({
      type: GET_TASKS,
      payload: { tasks: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_TASKS,
        payload: { tasks: [], total: 0, message }
      });
    });
  };
}
