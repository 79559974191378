/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_REFUND_QUEUES, GET_REFUND_QUEUES
} from 'src/actions/refundQueueActions';

const initialState = {
  items: [],
  total: 0,
  isFetching: false,
  message: ''
};

const refundQueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REFUND_QUEUES: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_REFUND_QUEUES: {
      const { refundQueues, total, message } = action.payload;

      return produce(state, (draft) => {
        draft.items = refundQueues;
        draft.total = total;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default refundQueueReducer;
