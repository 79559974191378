/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_TYPES
} from 'src/actions/productTypeActions';

const initialState = {
  types: []
};

const productTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPES: {
      const { types } = action.payload;

      return produce(state, (draft) => {
        draft.types = types;
      });
    }

    default: {
      return state;
    }
  }
};

export default productTypeReducer;
