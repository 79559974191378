import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_MEALS = '@meals/get-meals';
export const REQUEST_MEALS = '@meals/request-meals';

export function getMeals() {
  const request = axios.get(`${API_BASE_URL}/meal`);

  return (dispatch) => {
    dispatch({ type: REQUEST_MEALS });
    request.then((response) => dispatch({
      type: GET_MEALS,
      payload: { meals: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_MEALS,
        payload: { meals: [], message }
      });
    });
  };
}
