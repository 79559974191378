import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PAYMENT_BATCHES = '@invoice/get-payment-batches';
export const REQUEST_PAYMENT_BATCHES = '@invoice/request-payment-batches';

export function getPaymentBatches(page = 1, pagesize = 10, orderby = 'id', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.approved != null) {
      params.approved = filter.approved;
    }

    if (filter.completed != null) {
      params.completed = filter.completed;
    }

    if (filter.closed != null) {
      params.closed = filter.closed;
    }
  }

  const request = axios.get(`${API_BASE_URL}/payment-batch`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_PAYMENT_BATCHES });

    request.then((response) => dispatch({
      type: GET_PAYMENT_BATCHES,
      payload: { paymentBatches: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_PAYMENT_BATCHES,
        payload: { paymentBatches: [], total: 0, message }
      });
    });
  };
}
