import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_SUBSCRIPTION_BILLING_REPORT = '@products/get-subscription-billing-report';
export const REQUEST_SUBSCRIPTION_BILLING_REPORT = '@products/request-subscription-billing-report';

export function getSubscriptionBillingReport(subscriptionId, billingPeriodId) {
  const url = `${API_BASE_URL}/subscription/${subscriptionId}/billing?billing_period_id=${billingPeriodId !== null ? billingPeriodId : ''}`;

  const request = axios.get(url);

  return (dispatch) => {
    dispatch({ type: REQUEST_SUBSCRIPTION_BILLING_REPORT });

    request.then((response) => dispatch({
      type: GET_SUBSCRIPTION_BILLING_REPORT,
      payload: { report: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_SUBSCRIPTION_BILLING_REPORT,
        payload: { report: [], message }
      });
    });
  };
}
