import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_ALLOCATIONS = '@products/get-allocations';
export const REQUEST_ALLOCATIONS = '@products/request-allocations';

export function getAllocations(page = 1, pagesize = 10, orderby = '', orderdirection = '', subscriptionId = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  let url = `${API_BASE_URL}/subscription/allocation`;
  if (subscriptionId != null) {
    url = `${API_BASE_URL}/subscription/${subscriptionId}/allocation`;
  }

  const request = axios.get(url, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_ALLOCATIONS });

    request.then((response) => dispatch({
      type: GET_ALLOCATIONS,
      payload: { allocations: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_ALLOCATIONS,
        payload: { allocations: [], total: 0, message }
      });
    });
  };
}
