/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_UNITS
} from 'src/actions/unitActions';

const initialState = {
  units: []
};

const unitReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNITS: {
      const { units } = action.payload;

      return produce(state, (draft) => {
        draft.units = units;
      });
    }

    default: {
      return state;
    }
  }
};

export default unitReducer;
