import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_INVOICES = '@invoice/get-invoices';
export const REQUEST_INVOICES = '@invoice/request-invoices';

export function getInvoices(page = 1, pagesize = 10, orderby = 'id', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.customer_id != null) {
      params.customer_id = filter.customer_id;
    }

    if (filter.paid != null) {
      params.paid = filter.paid;
    }

    if (filter.overdue != null) {
      params.overdue = filter.overdue;
    }

    if (filter.synced != null) {
      params.synced = filter.synced;
    }
    
    if (filter.ticket_orders != null) {
      params.ticket_orders = filter.ticket_orders;
    }
    
    if (filter.query != null) {
      params.query = filter.query;
    }
  }

  const request = axios.get(`${API_BASE_URL}/invoice`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_INVOICES });

    request.then((response) => dispatch({
      type: GET_INVOICES,
      payload: { invoices: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_INVOICES,
        payload: { invoices: [], total: 0, message }
      });
    });
  };
}
