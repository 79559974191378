import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_SIBLING_DISCOUNTS = '@siblingDiscount/get-sibling-discounts';
export const REQUEST_SIBLING_DISCOUNTS = '@siblingDiscount/request-sibling-discounts';

export function getSiblingDiscounts() {
  const request = axios.get(`${API_BASE_URL}/sibling-discount`);

  return (dispatch) => {
    dispatch({ type: REQUEST_SIBLING_DISCOUNTS });

    request.then((response) => dispatch({
      type: GET_SIBLING_DISCOUNTS,
      payload: { siblingDiscounts: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
        console.log(message);
      }

      dispatch({
        type: GET_SIBLING_DISCOUNTS,
        payload: { siblingDiscounts: [], message }
      });
    });
  };
}
