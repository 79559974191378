import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_CATEGORIES = '@products/get-categories';
export const REQUEST_CATEGORIES = '@products/request-categories';

export function getCategories(page = 1, pagesize = 20, orderby = 'name', orderdirection = 'ASC', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.query != null) {
      params.query = filter.query;
    }
    if (filter.type_id != null) {
      params.type_id = filter.type_id;
    }
  }

  const request = axios.get(`${API_BASE_URL}/product/category`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_CATEGORIES });

    request.then((response) => dispatch({
      type: GET_CATEGORIES,
      payload: { categories: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_CATEGORIES,
        payload: { categories: [], total: 0, message }
      });
    });
  };
}
