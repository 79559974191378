import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_MINIMUM_USAGE_TYPES = '@branches/get-minimum-usage-types';

export function getMinimumUsageTypes() {
  const request = axios.get(`${API_BASE_URL}/minimum-usage-types`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_MINIMUM_USAGE_TYPES,
      payload: { types: response.data }
    }));
  };
}
