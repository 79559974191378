import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_SUBSCRIPTIONS = '@subscription/get-subscriptions';
export const REQUEST_SUBSCRIPTIONS = '@subscription/request-subscriptions';

export function getSubscriptions(page = 1, pagesize = 10, orderby = 'branch_id', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.branch_id != null) {
      params.branch_id = filter.branch_id;
    }

    if (filter.branch_group_id != null) {
      params.branch_group_id = filter.branch_group_id;
    }

    if (filter.customer_id != null) {
      params.customer_id = filter.customer_id;
    }

    if (filter.customer_consumer_id != null) {
      params.customer_consumer_id = filter.customer_consumer_id;
    }

    if (filter.product_id != null) {
      params.product_id = filter.product_id;
    }

    if (filter.cancelled != null) {
      params.cancelled = filter.cancelled;
    }

    if (filter.suspended != null) {
      params.suspended = filter.suspended;
    }

    if (filter.query != null) {
      params.query = filter.query;
    }

    if (filter.sibling_discount != null) {
      params.sibling_discount = filter.sibling_discount;
    }

    if (filter.discount != null) {
      params.discount = filter.discount;
    }
  }

  const request = axios.get(`${API_BASE_URL}/subscription`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_SUBSCRIPTIONS });

    request.then((response) => dispatch({
      type: GET_SUBSCRIPTIONS,
      payload: { subscriptions: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_SUBSCRIPTIONS,
        payload: { subscriptions: [], total: 0, message }
      });
    });
  };
}
