import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_DAILY_REPORTS = '@dailyReports/get-daily-reports';
export const REQUEST_DAILY_REPORTS = '@dailyReports/request-daily-reports';

export function getDailyReports(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.keyword != null && filter.keyword != '') {
      params.keyword = filter.keyword;
    }

    if (filter.date != null && filter.date != '') {
      params.date = filter.date;
    }

    if (filter.datefrom != null && filter.datefrom != '') {
      params.datefrom = filter.datefrom;
    }

    if (filter.dateto != null && filter.dateto != '') {
      params.dateto = filter.dateto;
    }
  }

  const request = axios.get(`${API_BASE_URL}/dailyreport`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_DAILY_REPORTS });

    request.then((response) => dispatch({
      type: GET_DAILY_REPORTS,
      payload: { dailyReports: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_DAILY_REPORTS,
        payload: { dailyReports: [], total: 0, message }
      });
    });
  };
}
