import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_ROUTES = '@purchase-orders/get-orders';
export const REQUEST_ROUTES = '@purchase-orders/request-orders';

export function getRoutes() {
  const request = axios.get(`${API_BASE_URL}/routes`);

  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTES });

    request
      .then((response) => 
        dispatch({
          type: GET_ROUTES,
          payload: {
            items: response.data,
            total: response.data.total
          }
        })
      )
      .catch((error) => {
        let message = '';
        if (error.response) {
          message = error.response.data;
        }

        dispatch({
          type: GET_ROUTES,
          payload: { items: [], total: 0, message }
        });
      });
  };
}
