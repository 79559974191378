/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_IDENTITY
} from 'src/actions/nationalRegisterActions';

const initialState = {
  identity: []
};

const nationalRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDENTITY: {
      const { identity } = action.payload;

      return produce(state, (draft) => {
        draft.identity = identity;
      });
    }

    default: {
      return state;
    }
  }
};

export default nationalRegisterReducer;
