import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_BLOCKED_SSNS = '@blockedSsn/get-blocked-ssns';
export const REQUEST_BLOCKED_SSNS = '@blockedSsn/request-blocked-ssns';

export function getBlockedSsns() {
  const request = axios.get(`${API_BASE_URL}/block-ssn`);

  return (dispatch) => {
    dispatch({ type: REQUEST_BLOCKED_SSNS });

    request.then((response) => dispatch({
      type: GET_BLOCKED_SSNS,
      payload: { blockedSsns: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_BLOCKED_SSNS,
        payload: { blockedSsns: [], message }
      });
    });
  };
}
