import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_ALLERGIES = '@allergy/get-allergies';
export const REQUEST_ALLERGIES = '@allergy/request-allergies';

export function getAllergies() {
  const request = axios.get(`${API_BASE_URL}/allergy`);

  return (dispatch) => {
    dispatch({ type: REQUEST_ALLERGIES });

    request.then((response) => dispatch({
      type: GET_ALLERGIES,
      payload: { allergies: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_ALLERGIES,
        payload: { allergies: [], message }
      });
    });
  };
}
