import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_IDENTITY = '@national-register/get-identity';

export function getIdentity(ssn) {
  const request = axios.get(`${API_BASE_URL}/national-register?ssn=${ssn}`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_IDENTITY,
      payload: { identity: response.data }
    }));
  };
}
