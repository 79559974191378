import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_TICKET_ORDERS = '@ticket-orders/get-ticket-orders';
export const REQUEST_TICKET_ORDERS = '@ticket-orders/request-ticket-orders';

export function getTicketOrders(page = 1, pagesize = 10, orderby = 'id', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  /* if (filter != null) {

    if (filter.customer_id != null) {
      params.customer_id = filter.customer_id;
    }

    if (filter.customer_consumer_id != null) {
      params.customer_consumer_id = filter.customer_consumer_id;
    }

    if (filter.product_id != null) {
      params.product_id = filter.product_id;
    }

    if (filter.cancelled != null) {
      params.cancelled = filter.cancelled;
    }

    if (filter.suspended != null) {
      params.suspended = filter.suspended;
    }

    if (filter.query != null) {
      params.query = filter.query;
    }

    if (filter.sibling_discount != null) {
      params.sibling_discount = filter.sibling_discount;
    }

    if (filter.discount != null) {
      params.discount = filter.discount;
    }
  } */

  if (filter != null) {
    if (filter.paid != null) {
      params.paid = filter.paid;
    }
    if (filter.processed != null) {
      params.processed = filter.processed;
    }
  }

  const request = axios.get(`${API_BASE_URL}/ticket-order`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_TICKET_ORDERS });

    request.then((response) => dispatch({
      type: GET_TICKET_ORDERS,
      payload: { ticketOrders: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_TICKET_ORDERS,
        payload: { ticketOrders: [], total: 0, message }
      });
    });
  };
}
