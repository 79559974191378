/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_MENUS, GET_MENUS
} from 'src/actions/menuActions';

const initialState = {
  items: [],
  isFetching: false,
  message: ''
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MENUS: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_MENUS: {
      const { menu, message } = action.payload;

      return produce(state, (draft) => {
        draft.items = menu;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default menuReducer;
