import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PRODUCT_CATEGORY_TYPES = '@product/get-types';

export function getProductCategoryTypes() {
  const request = axios.get(`${API_BASE_URL}/product/category/type`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_PRODUCT_CATEGORY_TYPES,
      payload: { types: response.data }
    }));
  };
}
