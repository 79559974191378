import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_UNITS = '@product/get-units';

export function getUnits() {
  const request = axios.get(`${API_BASE_URL}/unit`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_UNITS,
      payload: { units: response.data }
    }));
  };
}
