/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_CATEGORIES, GET_CATEGORIES
} from 'src/actions/recipeCategoryActions';

const initialState = {
  categories: [],
  total: 0,
  isFetching: false,
  message: ''
};

const recipeCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CATEGORIES: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_CATEGORIES: {
      const { categories, total, message } = action.payload;

      return produce(state, (draft) => {
        draft.categories = categories;
        draft.total = total;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default recipeCategoryReducer;
