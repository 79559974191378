/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_BRANCHES, GET_BRANCHES, SET_BRANCHES
} from 'src/actions/branchActions';

const initialState = {
  items: [],
  total: 0,
  isFetching: false,
  message: ''
};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_BRANCHES: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_BRANCHES: {
      const { branches, total, message } = action.payload;

      return produce(state, (draft) => {
        draft.items = branches;
        draft.total = total;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    case SET_BRANCHES: {
      const { branches } = action.payload;

      return produce(state, (draft) => {
        draft.items = branches;
      });
    }

    default: {
      return state;
    }
  }
};

export default branchReducer;
