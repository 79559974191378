import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PRODUCTS = '@products/get-products';
export const REQUEST_PRODUCTS = '@products/request-products';
export const SET_PRODUCTS = '@products/set-products';

export function getProducts(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.category_id != null) {
      params.category_id = filter.category_id;
    }

    if (filter.sku != null) {
      params.sku = filter.sku;
    }

    if (filter.product_type_id != null) {
      params.product_type_id = filter.product_type_id;
    }

    if (filter.query != null) {
      params.query = filter.query;
    }

    if (filter.product_type_queries != null) {
      params.product_type_queries = filter.product_type_queries;
    }
  }

  const request = axios.get(`${API_BASE_URL}/product`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_PRODUCTS });

    request.then((response) => dispatch({
      type: GET_PRODUCTS,
      payload: { products: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_PRODUCTS,
        payload: { products: [], total: 0, message }
      });
    });
  };
}

export function setProducts(products) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: { products }
    });
  };
}
