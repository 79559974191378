import React, { useState, useRef } from 'react';
import {
  Box, Button, IconButton, Popover, SvgIcon, TextField, Tooltip, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    language: settings.language
  });
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" onClick={handleOpen} ref={ref} size="large">
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {t('Settings')}
        </Typography>
        <Box mt={3}>
          <TextField
            fullWidth
            label={t('Language')}
            name="language"
            onChange={(event) => handleChange('language', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.language}
            variant="outlined"
          >
            <option key="en" value="en">English</option>
            <option key="is" value="is">Íslenska</option>
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('Save settings')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
