/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box, Drawer, Hidden, List, ListSubheader
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Home as HomeIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  BarChart as BarChartIcon,
  Package as PackageIcon,
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Settings as SettingsIcon,
  BookOpen as KitchenIcon,
  Clock as ClockIcon,
  File as FileIcon,
  Clipboard as ClipboardIcon,
  Truck as ShippingIcon,
  Tag as TagIcon,
  FileText as FileTextIcon
  
} from 'react-feather';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/customers',
        roles: ['CUSTOMERS']
      },
      {
        title: 'Branches',
        icon: HomeIcon,
        href: '/app/branches',
        roles: ['BRANCHES']
      },
      {
        title: 'Subscriptions',
        icon: CheckCircleIcon,
        href: '/app/subscriptions',
        roles: ['SUBSCRIPTIONS']
      },
      {
        title: 'Ticket orders',
        icon: TagIcon,
        href: '/app/ticket-orders',
        roles: ['SUBSCRIPTIONS']
      },
      {
        title: 'Products',
        icon: PackageIcon,
        href: '/app/products',
        roles: ['PRODUCTS']
      },
      {
        title: 'Orders',
        icon: ShippingIcon,
        roles: ['ORDERS'],
        items: [
          {
            title: 'Orders',
            href: '/app/purchase-orders',
            roles: ['ORDERS']
          },
          {
            title: 'Overview',
            href: '/app/purchase-orders/overview',
            roles: ['ORDERS']
          },
        ]
      },
      {
        title: 'Daily reports',
        icon: FileTextIcon,
        href: '/app/dailyReports',
        roles: ['DAILYREPORTS']
      },
      {
        title: 'Menu',
        icon: ClipboardIcon,
        roles: ['MENU'],
        items: [
          {
            title: 'Weekly menus',
            href: '/app/menus',
            roles: ['MENU']
          },
          {
            title: 'Recipes',
            href: '/app/recipes',
            roles: ['MENU']
          },
          {
            title: 'Pan files',
            href: '/app/panFiles',
            roles: ['PRODUCTION']
          }
        ]
      },
      {
        title: 'Billing',
        icon: FileIcon,
        roles: ['BILLING'],
        items: [
          {
            title: 'All invoices',
            href: '/app/invoices',
            roles: ['BILLING']
          },
          {
            title: 'New invoices',
            href: '/app/invoices/new',
            roles: ['BILLING']
          },
          {
            title: 'Ticket orders invoices',
            href: '/app/invoices/ticket-orders',
            roles: ['BILLING']
          },
          {
            title: 'Payment batches',
            href: '/app/payment-batches',
            roles: ['BILLING']
          },
          {
            title: 'Refund queues',
            href: '/app/refund-queues',
            roles: ['BILLING']
          },
          {
            title: 'Suspended subscriptions',
            href: '/app/suspended',
            roles: ['BILLING']
          }]
      },
      {
        title: 'Holidays',
        icon: CalendarIcon,
        href: '/app/holidays',
        roles: ['HOLIDAYS']
      },
    ]
  },
  {
    subheader: 'Administration',
    items: [
      {
        title: 'Settings',
        icon: SettingsIcon,
        roles: ['SETTINGS'],
        items: [
          {
            title: 'Product categories',
            href: '/app/products/categories',
            roles: ['SUBSCRIPTIONS', 'PRODUCTION']
          },
          {
            title: 'Recipe categories',
            href: '/app/recipes/categories',
            roles: ['PRODUCTION']
          },
          {
            title: 'Subscription periods',
            href: '/app/settings/periods',
            roles: ['SUBSCRIPTIONS', 'BILLING']
          },
          {
            title: 'Billing periods',
            href: '/app/settings/billing-periods',
            roles: ['BILLING']
          },
          {
            title: 'Tax rates',
            href: '/app/settings/taxRates',
            roles: ['BILLING']
          },
          {
            title: 'Routes',
            href: '/app/settings/routes',
            roles: ['BRANCHES']
          },
          {
            title: 'Allergies',
            href: '/app/settings/allergies',
            roles: ['SUBSCRIPTIONS', 'PRODUCTION']
          },
          {
            title: 'Payment methods',
            href: '/app/settings/paymentMethods',
            roles: ['BILLING']
          },
          {
            title: 'Block List',
            href: '/app/settings/blockList',
            roles: ['ADMIN']
          },
          {
            title: 'Sibling discounts',
            href: '/app/settings/siblingDiscounts',
            roles: ['BILLING']
          }
        ]
      },
      {
        title: 'Reports',
        icon: BarChartIcon,
        roles: ['REPORTS'],
        items: [
          {
            title: 'Quantity report',
            href: '/app/reports/quantity',
            roles: ['SUBSCRIPTIONS']
          },
          {
            title: 'Allergy report',
            href: '/app/reports/allergy',
            roles: ['SUBSCRIPTIONS']
          },
          {
            title: 'Vegan report',
            href: '/app/reports/vegan',
            roles: ['SUBSCRIPTIONS']
          },
          {
            title: 'Sibling discount',
            href: '/app/reports/siblingDiscount',
            roles: ['BILLING']
          },
          {
            title: 'Discount',
            href: '/app/reports/discount',
            roles: ['BILLING']
          },
          {
            title: 'Allocation report',
            href: '/app/reports/allocation',
            roles: ['SUBSCRIPTIONS']
          },
          {
            title: 'Pending refunds',
            href: '/app/reports/pending-refunds',
            roles: ['BILLING']
          },
          {
            title: 'Assets',
            href: '/app/reports/asset',
            roles: ['BRANCHES']
          }
        ]
      },
      {
        title: 'Tasks',
        icon: ClockIcon,
        href: '/app/tasks',
        roles: ['ADMIN']
      },
      {
        title: 'Users and roles',
        icon: UserCheckIcon,
        href: '/app/users',
        roles: ['ADMIN']
      }
    ]
  }
];

function renderNavItems({ t, items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          t, acc, item, ...rest
        }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  t,
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={t(item.title)}
      >
        {renderNavItems({
          t,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={t(item.title)}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();

  const { t } = useTranslation();

  const account = useSelector((state) => state.account);

  function hasRole(roles) {
    if (account.user.type_id == 2) return true; // Admin
    return account.user.roles.some((x) => roles.includes(x.name));
  }

  function filterNavConfig(config) {
    const configCopy = config.slice();

    configCopy.forEach((section) => {
      // Filter sections by roles
      section.items = section.items.filter((x) => hasRole(x.roles));
      section.items.forEach((item, itemIndex) => {
        // Filter items by roles
        if (item.items) {
          item.items = item.items.filter((x) => hasRole(x.roles));
          // Remove items that have no items
          if (item.items.length == 0) {
            section.items.splice(itemIndex, 1);
          }
        }
      });
    });
    // Remove sections that have no items
    configCopy.forEach((section, sectionIndex) => {
      if (section.items.length == 0) {
        configCopy.splice(sectionIndex, 1);
      }
    });

    return configCopy;
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {filterNavConfig(navConfig).map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {t(config.subheader)}
                </ListSubheader>
              )}
            >
              {renderNavItems({ t, items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          sx={{ displayPrint: "none" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          sx={{ displayPrint: "none" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
