import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_USERS = '@users/get-users';
export const REQUEST_USERS = '@users/request-users';

export function getUsers(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.query != null) {
      params.query = filter.query;
    }
  }

  const request = axios.get(`${API_BASE_URL}/user`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_USERS });

    request.then((response) => dispatch({
      type: GET_USERS,
      payload: { users: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_USERS,
        payload: { users: [], total: 0, message }
      });
    });
  };
}
