/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_SIBLING_DISCOUNTS, GET_SIBLING_DISCOUNTS
} from 'src/actions/siblingDiscountActions';

const initialState = {
  items: [],
  isFetching: false,
  message: ''
};

const siblingDiscountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SIBLING_DISCOUNTS: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_SIBLING_DISCOUNTS: {
      const { siblingDiscounts, message } = action.payload;

      return produce(state, (draft) => {
        draft.items = siblingDiscounts;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default siblingDiscountReducer;
