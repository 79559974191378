import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PERIODS = '@periods/get-periods';
export const REQUEST_PERIODS = '@periods/request-periods';

export function getPeriods() {
  const request = axios.get(`${API_BASE_URL}/period`);

  return (dispatch) => {
    dispatch({ type: REQUEST_PERIODS });

    request.then((response) => dispatch({
      type: GET_PERIODS,
      payload: { periods: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_PERIODS,
        payload: { periods: [], message }
      });
    });
  };
}
