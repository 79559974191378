/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_COUNTRIES,
  GET_CITIES
} from 'src/actions/countryActions';

const initialState = {
  countries: [],
  cities: []
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES: {
      const { countries } = action.payload;

      return produce(state, (draft) => {
        draft.countries = countries;
      });
    }

    case GET_CITIES: {
      const { cities } = action.payload;

      return produce(state, (draft) => {
        draft.cities = cities;
      });
    }

    default: {
      return state;
    }
  }
};

export default countryReducer;
