import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_CURRENCIES = '@allergy/get-currencies';

export function getCurrencies() {
  const request = axios.get(`${API_BASE_URL}/currency`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_CURRENCIES,
      payload: { currencies: response.data }
    }));
  };
}
