import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_BRANCHES = '@branches/get-branches';
export const REQUEST_BRANCHES = '@branches/request-branches';
export const SET_BRANCHES = '@branches/set-branches';

export function getBranches(page = 1, pagesize = 10, orderby = 'name', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.branch_type_id != null) {
      params.branch_type_id = filter.branch_type_id;
    }

    if (filter.exclude_product_id != null) {
      params.exclude_product_id = filter.exclude_product_id;
    }

    if (filter.active != null && filter.active != -1) {
      params.active = filter.active;
    }

    if (filter.query != null) {
      params.query = filter.query;
    }

    if (filter.all != null) {
      params.all = filter.all;
    }

    if (filter.details != null) {
      params.details = filter.details;
    }
  }

  const request = axios.get(`${API_BASE_URL}/branch`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_BRANCHES });

    request.then((response) => dispatch({
      type: GET_BRANCHES,
      payload: { branches: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_BRANCHES,
        payload: { branches: [], total: 0, message }
      });
    });
  };
}

export function setBranches(branches) {
  return (dispatch) => {
    dispatch({
      type: SET_BRANCHES,
      payload: { branches }
    });
  };
}
