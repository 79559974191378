import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_BILLING_PERIODS = '@periods/get-billing-periods';
export const REQUEST_BILLING_PERIODS = '@periods/request-billing-periods';

export function getBillingPeriods() {
  const request = axios.get(`${API_BASE_URL}/billing-period`);

  return (dispatch) => {
    dispatch({ type: REQUEST_BILLING_PERIODS });

    request.then((response) => dispatch({
      type: GET_BILLING_PERIODS,
      payload: { billingPeriods: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_BILLING_PERIODS,
        payload: { billingPeriods: [], message }
      });
    });
  };
}
