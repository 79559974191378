import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PURCHASE_ORDERS = '@purchase-orders/get-orders';
export const REQUEST_PURCHASE_ORDERS = '@purchase-orders/request-orders';
export const SET_PURCHASE_ORDERS = '@purchase-orders/set-orders';

export function getPurchaseOrders(
  page = 1,
  pagesize = 10,
  orderby = 'deliver_at',
  orderdirection = 'desc',
  filter = null
) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.date != null) {
      params.date = filter.date;
    }
    
    if (filter.branch != null) {
      params.branch = filter.branch;
    }

    if (filter.processed != null) {
      params.processed = filter.processed;
    }
  }

  const request = axios.get(`${API_BASE_URL}/purchase-orders`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_PURCHASE_ORDERS });

    request
      .then((response) => 
        dispatch({
          type: GET_PURCHASE_ORDERS,
          payload: {
            purchaseOrders: response.data.data,
            total: response.data.total
          }
        })
      )
      .catch((error) => {
        let message = '';
        if (error.response) {
          message = error.response.data;
        }

        dispatch({
          type: GET_PURCHASE_ORDERS,
          payload: { purchaseOrders: [], total: 0, message }
        });
      });
  };
}

export function setPurchaseOrders(purchaseOrders) {
  return (dispatch) => {
    dispatch({
      type: SET_PURCHASE_ORDERS,
      payload: { purchaseOrders }
    });
  };
}
