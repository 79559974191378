import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_TAX_RATES = '@taxRate/get-tax-rates';
export const REQUEST_TAX_RATES = '@taxRate/request-tax-rates';

export function getTaxRates() {
  const request = axios.get(`${API_BASE_URL}/tax-rate`);

  return (dispatch) => {
    dispatch({ type: REQUEST_TAX_RATES });

    request.then((response) => dispatch({
      type: GET_TAX_RATES,
      payload: { taxRates: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_TAX_RATES,
        payload: { taxRates: [], message }
      });
    });
  };
}
