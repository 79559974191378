import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_USER_TYPES = '@taxRate/get-user-types';
export const REQUEST_USER_TYPES = '@taxRate/request-user-types';

export function getUserTypes() {
  const request = axios.get(`${API_BASE_URL}/user/type`);

  return (dispatch) => {
    dispatch({ type: REQUEST_USER_TYPES });

    request.then((response) => dispatch({
      type: GET_USER_TYPES,
      payload: { userTypes: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_USER_TYPES,
        payload: { userTypes: [], message }
      });
    });
  };
}
