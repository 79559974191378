import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_CUSTOMERS = '@customer/get-customers';
export const REQUEST_CUSTOMERS = '@customer/request-customers';
export const SET_CUSTOMERS = '@customer/set-customers';

export function getCustomers(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.customer_type_id != null) {
      params.customer_type_id = filter.customer_type_id;
    }

    if (filter.query != null) {
      params.query = filter.query;
    }
  }

  const request = axios.get(`${API_BASE_URL}/customer`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_CUSTOMERS });

    request.then((response) => dispatch({
      type: GET_CUSTOMERS,
      payload: { customers: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_CUSTOMERS,
        payload: { customers: [], total: 0, message }
      });
    });
  };
}

export function setCustomers(customers) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMERS,
      payload: { customers }
    });
  };
}
