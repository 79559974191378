import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_COUNTRIES = '@country/get-countries';
export const GET_CITIES = '@country/get-cities';

export function getCountries() {
  const request = axios.get(`${API_BASE_URL}/country`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_COUNTRIES,
      payload: { countries: response.data }
    }));
  };
}

export function getCities(countryId) {
  const request = axios.get(`${API_BASE_URL}/country/${countryId}`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_CITIES,
      payload: { cities: response.data }
    }));
  };
}
