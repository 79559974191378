import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_MENUS = '@menu/get-menus';
export const REQUEST_MENUS = '@menu/request-menus';

export function getMenus(filter = null) {
  const params = {};
  if (filter != null) {
    if (filter.datefrom != null) {
      params.datefrom = filter.datefrom;
    }

    if (filter.dateto != null) {
      params.dateto = filter.dateto;
    }
  }

  const request = axios.get(`${API_BASE_URL}/menu`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_MENUS });
    request.then((response) => dispatch({
      type: GET_MENUS,
      payload: { menu: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_MENUS,
        payload: { menu: [], message }
      });
    });
  };
}
