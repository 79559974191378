import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_HOLIDAYS = '@holidays/get-holidays';
export const REQUEST_HOLIDAYS = '@holidays/request-holidays';

export function getHolidays(page = 1, pagesize = 10, orderby = '', orderdirection = '', filter = null) {
  const params = {
    page,
    orderby,
    orderdirection
  };

  if (pagesize > 0) {
    params.pagesize = pagesize;
  }

  if (filter != null) {
    if (filter.query != null) {
      params.query = filter.query;
    }

    if (filter.date != null) {
      params.date = filter.date;
    }

    if (filter.branch_type_id != null) {
      params.branch_type_id = filter.branch_type_id;
    }

    if (filter.branch_id != null) {
      params.branch_id = filter.branch_id;
    }

    if (filter.show_all !== 0) {
      params.show_all = filter.show_all;
    }
  }

  const request = axios.get(`${API_BASE_URL}/holiday`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_HOLIDAYS });

    request.then((response) => dispatch({
      type: GET_HOLIDAYS,
      payload: { holidays: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_HOLIDAYS,
        payload: { holidays: [], total: 0, message }
      });
    });
  };
}
