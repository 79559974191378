/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CURRENCIES
} from 'src/actions/currencyActions';

const initialState = {
  currencies: []
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES: {
      const { currencies } = action.payload;

      return produce(state, (draft) => {
        draft.currencies = currencies;
      });
    }

    default: {
      return state;
    }
  }
};

export default currencyReducer;
