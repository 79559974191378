import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import countryReducer from './countryReducer';
import allergyReducer from './allergyReducer';
import customerReducer from './customerReducer';
import branchReducer from './branchReducer';
import productReducer from './productReducer';
import periodReducer from './periodReducer';
import billingPeriodReducer from './billingPeriodReducer';
import userReducer from './userReducer';
import currencyReducer from './currencyReducer';
import taxRateReducer from './taxRateReducer';
import paymentMethodReducer from './paymentMethodReducer';
import subscriptionReducer from './subscriptionReducer';
import nationalRegisterReducer from './nationalRegisterReducer';
import userRoleReducer from './userRoleReducer';
import allocationReducer from './allocationReducer';
import subscriptionBillingReportReducer from './subscriptionBillingReportReducer';
import holidayReducer from './holidayReducer';
import blockedSsnReducer from './blockedSsnReducer';
import siblingDiscountReducer from './siblingDiscountReducer';
import invoiceReducer from './invoiceReducer';
import paymentBatchReducer from './paymentBatchReducer';
import refundQueueReducer from './refundQueueReducer';
import taskReducer from './taskReducer';
import productCategoryReducer from './productCategoryReducer';
import productCategoryTypeReducer from './productCategoryTypeReducer';
import productTypeReducer from './productTypeReducer';
import purchaseOrderReducer from './purchaseOrderReducer';
import unitReducer from './unitReducer';
import recipeReducer from './recipeReducer';
import recipeCategoryReducer from './recipeCategoryReducer';
import mealReducer from './mealReducer';
import panFileReducer from './panFileReducer';
import dailyReportReducer from './dailyReportReducer';
import menuReducer from './menuReducer';
import userTypeReducer from './userTypeReducer';
import ticketOrderReducer from './ticketOrderReducer';
import purchaseOrderProductReducer from './purchaseOrderProductReducer';
import routeReducer from './routeReducer';
import minimumUsageTypeReducer from './minimumUsageTypeReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  country: countryReducer,
  allergy: allergyReducer,
  customer: customerReducer,
  branch: branchReducer,
  product: productReducer,
  period: periodReducer,
  billingPeriod: billingPeriodReducer,
  user: userReducer,
  currency: currencyReducer,
  taxRate: taxRateReducer,
  paymentMethod: paymentMethodReducer,
  form: formReducer,
  subscription: subscriptionReducer,
  nationalRegister: nationalRegisterReducer,
  userRole: userRoleReducer,
  userType: userTypeReducer,
  unit: unitReducer,
  allocation: allocationReducer,
  subscriptionBillingReport: subscriptionBillingReportReducer,
  holiday: holidayReducer,
  blockedSsn: blockedSsnReducer,
  siblingDiscount: siblingDiscountReducer,
  invoice: invoiceReducer,
  paymentBatch: paymentBatchReducer,
  refundQueue: refundQueueReducer,
  task: taskReducer,
  productCategory: productCategoryReducer,
  productCategoryType: productCategoryTypeReducer,
  productType: productTypeReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseOrderProduct: purchaseOrderProductReducer,
  recipe: recipeReducer,
  recipeCategory: recipeCategoryReducer,
  meal: mealReducer,
  panFile: panFileReducer,
  dailyReport: dailyReportReducer,
  menu: menuReducer,
  ticketOrder: ticketOrderReducer,
  route: routeReducer,
  minimumUsageType: minimumUsageTypeReducer
});

export default rootReducer;
