import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_REFUND_QUEUES = '@invoice/get-refund-queues';
export const REQUEST_REFUND_QUEUES = '@invoice/request-refund-queues';

export function getRefundQueues(page = 1, pagesize = 10, orderby = 'id', orderdirection = 'asc', filter = null) {
  const params = {
    page,
    pagesize,
    orderby,
    orderdirection
  };

  if (filter != null) {
    if (filter.approved != null) {
      params.approved = filter.approved;
    }

    if (filter.completed != null) {
      params.completed = filter.completed;
    }
  }

  const request = axios.get(`${API_BASE_URL}/refund-queue`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_REFUND_QUEUES });

    request.then((response) => dispatch({
      type: GET_REFUND_QUEUES,
      payload: { refundQueues: response.data.data, total: response.data.total }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_REFUND_QUEUES,
        payload: { refundQueues: [], total: 0, message }
      });
    });
  };
}
